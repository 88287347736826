import { useNavigate } from 'react-router-dom'
import './header.css'
 export const Header = () => {
    let navigate = useNavigate();
    return (
        <>
            <div id="icon-section">
                <div className="row justify-content-end text-align-center">
                    <div className="icon circle-icon"><a rel="noreferrer" target="_blank" href="https://instagram.com/leslee.echivarre"><img alt="insta" src="img/icons/insta.png" /></a></div>
                    <div className="icon circle-icon"><a rel="noreferrer" target="_blank" href="https://youtube.com/channel/UCEQj1vGCM0NFy8czdY729oQ"><img alt="youtube" src="img/icons/youtube.png" /></a></div>
                    <div className="icon circle-icon"><a rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/leslee-echivarre"><img alt="linkedin" src="img/icons/linkedin.png" /></a></div>
                    {/* <div className="icon circle-icon"><a target="_blank" href="https://open.spotify.com/artist/6P6DzS1ATtjuMgINnYDi8Z?si=tZhL76wpTOeKINA9CNt8Uw"><img src="img/icons/spotify.png" /></a></div> */}
                </div>
            </div>
            <div id="header">
                <img className="brand" src="/img/icons/brand_pr.png" alt="brand" />
                <div className='row justify-content-center pt-5 fade-top'>
                    <h1 className="times">leslee echivarre</h1>
                </div>
                <hr className='style8 mt-1 mb-1'/>
                <div className='row justify-content-center' style={{textAlign: "center"}}>
                    <div className="col-lg-2 col-sm-12 my-navbar-item">
                        <a onClick={() => {
                        navigate('/'); 
                        window.dispatchEvent(new Event('popstate'))
                    }} className="times cormorant fade-top">home</a>
                    </div>
                    <div className="col-lg-2 col-sm-12 my-navbar-item">
                        <a onClick={() => {
                        navigate('/about'); 
                        window.dispatchEvent(new Event('popstate'))
                    }} className="times cormorant fade-top">about</a>
                    </div>
                    <div className="col-lg-2 col-sm-12 my-navbar-item portfolio mb-5 pb-5">
                        <a onClick={() => {
                        navigate('/social-media'); 
                        window.dispatchEvent(new Event('popstate'))
                    }} className="times cormorant fade-top">portfolio</a>
                    </div>
                </div>
            </div>
        </>
    )
 }