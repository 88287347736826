import { useEffect } from "react";

export default function Home() {
    // useEffect(() => {

    //     var homeCont = document.getElementById("home-cont");
    //     var leslee = document.getElementById("leslee");
    //     console.log(homeCont);
    //     console.log(leslee.clientHeight);
    //     homeCont.style.height = leslee.clientHeight;
    // }, [useEffect])
    return (
        <>
            <div id="home">
                
            <div className='row welcome-leslee fade'>
                <img id='leslee' src='./img/leslee6.png' className="projects-img" alt="me" style={{maxHeight: "100vh"}} />
                <div className='welcome-text fade col-xl-6 col-lg-12 col-md-12 justify-content-center'>
                    <h3 className="welcome-text-header very-spaced" style={{width: "100%", fontFamily: 'Times New Roman, Times, serif'}}>
                        <strong>welcome!</strong>
                    </h3>
                    <p className="welcome-text-g p-3" style={{width: "100%", fontFamily: 'Times New Roman, Times, serif', margin: 0}}>
                        <i>hi, i'm leslee echivarre! nice to meet you!</i>
                    </p>
                    <h3 className="welcome-text-p p-3" style={{fontFamily: '"Times New Roman", "Times", "serif"'}}>    
                    I am a recent graduate at Montclair State University with a degree in
                    Communication and Media Arts. I am looking forward to learn and grow my
                    career in production, media, and strategic communication. I am hoping to
                    find job and internship opportunities that not only enhance my skills and
                    knowledge, but allow me to make a difference in the world!
                    </h3>
                    <br />
                    <h3 className="welcome-contact" style={{fontFamily: '"Times New Roman", "Times", "serif"'}}>    
                        <a href="https://www.linkedin.com/in/leslee-echivarre/" target="_blank"><button className="leslee-button contact-me-button">CONTACT ME</button></a>
                    </h3>
                </div>
                <div className="col-xl-6 col-lg-2 col-md-12 col-sm-12" style={{top: '-40vw', 'pointerEvents': 'none'}}></div>
            </div>
            {/* words and testimonials */}
            <div id="keith" className="fade mt-2 pt-5 pb-1 mb-1 pink-bg testimonials image-container">
                <div className="row">
                    <h2 className="text-cent bold w-100 kinda-spaced">words & testimonials</h2>
                    <div className="col-lg-10 col-sm-12 text-block">
                        <p className="">"Leslee is an outstanding and motivated student who did excellent client work in my Hawk Communications
                        course. The class is an upper-level elective that mirrors a strategic communications agency and allows students to
                        work with local businesses and nonprofits on social media campaigns, event planning, media pitching and other
                        PR and marketing tactics. Along with one other student on the account team, she planned and executed a robust
                        content plan for her client, the National Music Council. Her excellent writing, organizational and content
                        creation skills were critical to increasing awareness and engagement on the client’s social media platforms and
                        website. In addition, she planned and executed events that also contributed to a significant increase in social
                        media followers and engagement with board members, donors, educators and musicians."</p>
                    </div>
                    <div className="col-lg-2 col-sm-12 text-block">
                        <div className="keith-container"><img src="./img/home/keith.png" /></div>
                        <div className="text-cent">
                            <strong>Keith Green, APR</strong>
                            <br></br>
                            <p className="desc">Assistant Professor of Public Relations and Strategic
                            Communications/Coordinator of Strategic
                            Communications</p>
                            <br></br>
                            <a href="https://lesleeechivarre.s3.amazonaws.com/Leslee_Echivarre_Letter_of_Recommendation.pdf" target="_blank"><button className="leslee-button read-letter-button spaced">READ FULL LETTER HERE</button></a>
                        </div>
                    </div>
                </div>
                <hr className='fade style8' />
                <div id="bev" className="mb-5 row testimonials flex-reverse">
                    <div className="col-lg-10 col-sm-12 text-block">
                        <p className="">"If ever there were a student whom I believe would change the world, it is Leslee Echivarre. Leslee possesses
                            a very unique combination of creativity, determination, and kindness. She is unlike any student I have ever
                            had and though small in stature, she leaves a big mark...
                            <br></br><br></br>In the words of Shakespeare, “And though she be but small, she is fierce.” Leslee is fierce in her commitment
                            to create change in the world…and she possesses the creativity, determination, and kindness to do just that."</p>
                    </div>
                    <div className="col-lg-2 col-sm-12 text-block">
                        <div className="bev-container"><img src="./img/home/bev.png" /></div>
                        <div className="text-cent text-responsive-smallest">
                            <strong>Beverly Zapatka Weihz</strong>
                            <br></br>
                            <p className="desc">Stateliner Media</p>
                            <br></br>
                            <a href="https://lesleeechivarre.s3.amazonaws.com/Leslee_Echivarre_-_Reference_Letter.pdf" target="_blank"><button className="leslee-button read-letter-button spaced">READ FULL LETTER HERE</button></a>
                        </div>
                    </div>
                </div>
            </div>
        {/* <div className="fade mt-5 mb-5 pt-5 pb-5" style={{backgroundColor: "white"}}>
            <div className="row justify-content-center">
                <a style={{color: "black"}} target="_blank" href="https://www.linkedin.com/in/leslee-echivarre"><button style={{backgroundColor: '#c9b29e', color: "black"}} type="button" className="times btn btn-secondary btn-lg text-responsive">Contact Me Here</button></a>
            </div>
            </div>
            */}
            </div>
      </>
    )
}