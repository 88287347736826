import './about.css'
export default function About() {
    return (
        <>
        <div className="row about-me pb-5">
            <div className='fade row justify-content-center'>
                {/* <div className="col-xl-1 col-lg-12"></div> */}
                <div className="col-xl-5 col-lg-12">
                    
                <h1 className="fade title cormorant mt-5 text-start">about me</h1>
                <h2 className="text-responsive-smaller">
                Hello, I’m Leslee! I’m a recent college graduate living in the New York City Metropolitan Area. I have a bachelor’s degree in Communication and Media Arts and I am looking forward to share my knowledge and skills to the world!
                </h2>
                <br></br>
                <h2 className='text-responsive-smaller'>
                    Growing up, I have always wanted to make the world a 
                    <strong><i> better place. </i></strong>
                    By
                    being involved in my high school’s media & music departments, I learned
                    that my love for communication, media, & music not only helped me
                    <strong><i> explore my creativity, </i></strong>
                     but also allowed me to 
                     <strong><i> make a difference in the world,</i></strong>
                    whether big or small. This led me to find passion for this field and
                    it has led me to great opportunities which I am forever grateful for.
                </h2>
                <br></br>
                <h2 className='text-responsive-smaller'><strong>Experience:</strong></h2>
                <h2 className='text-responsive-smaller'>My experiences include:
                    <ul>
                        <li>2+ years of social media management</li>
                        <li>2+ years of public relations</li>
                        <li>2+ years of TV production and newspaper writing</li>
                        <li>6+ years of content creation and graphic design</li>
                        <li>5+ years of customer service</li>
                    </ul>
                </h2>
                <br></br>
                <h2 className='text-responsive-smaller'><strong>Skills:</strong></h2>
                <h2 className='text-responsive-smaller'><strong>Computer:</strong></h2>
                <h2 className='text-responsive-smaller'>My experiences include:
                    <ul>
                        <li>Adobe Creative Suites: Adobe Premiere Pro and Adobe Photoshop</li>
                        <li>Canva, Audacity, Procreate, Capcut</li>
                        <li>Meta Business Suite</li>
                        <li>Microsoft Office: Word, Excel, and Powerpoint</li>
                    </ul>
                <h2 className='text-responsive-smaller'><strong>Social Media:</strong></h2>
                    <ul>
                        <li>Instagram, Tiktok, Facebook, Twitter, LinkedIn</li>
                    </ul>
                <h2 className='text-responsive-smaller'><strong>Language(s):</strong></h2>
                    <ul>
                        <li>Bilingual in Tagalog and English</li>
                    </ul>
                </h2>
                </div>

            <div className="col-xl-4 col-lg-12 mt-5">
                <img src="img/leslee5.jpg" width="100%" height="auto" />
                <div className="welcome-contact" style={{fontFamily: '"Times New Roman", "Times", "serif"', fontSize: '2rem'}}>    
                    <a href="https://lesleeechivarre.s3.amazonaws.com/Leslee_Echivarre_Resume.pdf" target="_blank"><button className='leslee-button resume-button very-spaced mt-5'>VIEW MY RESUME</button></a>
                </div>
            </div>
            <div className="col-xl-1 col-lg-12"></div>
            </div>

            <hr className='mt-5 fade style8' />
            {/* <br></br> */}
            <h1 className="my-hobbies fade text-cent w-100">my hobbies</h1>
            <div className="insta-row mt-5 fade row justify-content-center">
                <iframe title="lesleemakesstuff" frameBorder={0} className='p-3 col-lg-6 col-sm-12' src={'https://lesleeechivarre.s3.amazonaws.com/insta1.htm' } ></iframe>
                <iframe title="happyfordoodles" frameBorder={0} className='p-3 col-lg-6 col-sm-12' src={'https://lesleeechivarre.s3.amazonaws.com/insta2.htm' } ></iframe>
            </div>
        </div>
      </>
    )
}