              
export default function Writing() {
    var karux = [];
    var seeing = [];
    var talent = [];
    var other = [];
    var dodie = [];

    dodie.push(<div className="carousel-item active"><img alt="img" className="d-block w-100" src={`./img/writing/dodie/0.jpg`} width='auto' height='auto'></img></div>)
    karux.push(<div className="carousel-item active"><img alt="img" className="d-block w-100" src={`./img/writing/karux/0.png`} width='auto' height='auto'></img></div>)
    other.push(<div className="carousel-item active"><img alt="img" className="d-block w-100" src={`./img/writing/other/0.png`} width='auto' height='auto'></img></div>)
    seeing.push(<div className="carousel-item active"><img alt="img" className="d-block w-100" src={`./img/writing/seeing/0.png`} width='auto' height='auto'></img></div>)
    talent.push(<div className="carousel-item active"><img alt="img" className="d-block w-100" src={`./img/writing/talent/0.png`} width='auto' height='auto'></img></div>)
    for (let i = 1; i < 7; i++){
        dodie.push(<div className="carousel-item"><img alt="img" className="d-block w-100" src={`./img/writing/dodie/${i}.jpg`} width='auto' height='auto'></img></div>)
    }
    for (let i = 1; i < 3; i++){
        karux.push(<div className="carousel-item"><img alt="img" className="d-block w-100" src={`./img/writing/karux/${i}.png`} width='auto' height='auto'></img></div>)
        other.push(<div className="carousel-item"><img alt="img" className="d-block w-100" src={`./img/writing/other/${i}.png`} width='auto' height='auto'></img></div>)
    }
    for (let i = 1; i < 4; i++){
        seeing.push(<div className="carousel-item"><img alt="img" className="d-block w-100" src={`./img/writing/seeing/${i}.png`} width='auto' height='auto'></img></div>)
    }
    for (let i = 1; i < 2; i++){
        talent.push(<div className="carousel-item"><img alt="img" className="d-block w-100" src={`./img/writing/talent/${i}.png`} width='auto' height='auto'></img></div>)
    }

    return (
        <>
        <div className="container">
        
            <h1 className="title fade cormorant mt-5">writing</h1>
            <div id='works' className='row justify-content-center' style={{margin: 0}}>
                <div className="fade mt-5 col-lg-6 col-sm-12">
                    {/* dodie */}
                    <div id="dodie" className="carousel slide" data-ride="carousel" style={{width: "100%", height: "100%"}}>
                        <div className="carousel-inner">
                            {dodie}
                        </div>
                        <a className="carousel-control-prev" href="#dodie" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#dodie" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>
                <div className="fade mt-5 col-lg-6 col-sm-12">
                    {/* karux */}
                    <div id="karux" className="carousel slide" data-ride="carousel" style={{width: "100%", height: "100%"}}>
                        <div className="carousel-inner">
                            {karux}
                        </div>
                        <a className="carousel-control-prev" href="#karux" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#karux" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>
                <div className="fade mt-5 col-lg-6 col-sm-12">
                    {/* seeing  */}
                    <div id="seeing" className="carousel slide" data-ride="carousel" style={{width: "100%", height: "100%"}}>
                        <div className="carousel-inner">
                            {seeing}
                        </div>
                        <a className="carousel-control-prev" href="#seeing" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#seeing" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>
                <div className="fade mt-5 col-lg-6 col-sm-12">
                    {/* talent */}
                    <div id="talent" className="carousel slide" data-ride="carousel" style={{width: "100%", height: "100%"}}>
                        <div className="carousel-inner">
                            {talent}
                        </div>
                        <a className="carousel-control-prev" href="#talent" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#talent" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>
                <div className="fade mt-5 col-lg-6 col-sm-12">
                    {/* other  */}
                    <div id="other" className="carousel slide" data-ride="carousel" style={{width: "100%", height: "100%"}}>
                        <div className="carousel-inner">
                            {other}
                        </div>
                        <a className="carousel-control-prev" href="#other" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#other" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
      </>
    )
}