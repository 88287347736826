import './social-media.css'
export default function SocialMedia() {
    // var files = [];

    // files.push(<div className="carousel-item active"><img className="d-block w-100" src='./img/social-media/0.png' width='auto' height='auto' /></div>)
    // files.push(<div className="carousel-item"><img className="d-block w-100" src='./img/social-media/1.png' width='auto' height='auto' /></div>)
    // files.push(<div className="carousel-item"><img className="d-block w-100" src='./img/social-media/2.jpg' width='auto' height='auto' /></div>)
    
    return (
        <>
            <div className='row image-container justify-content-center fade'>
                <div className="col-xl-6 col-lg-12 p-4 image-text times text-responsive">
                    <h2 className='text-responsive-bigger'>social media management & content creation</h2>
                    <p className='bit-spaced'>Having grown up alongside the evolution of social media, I possess a deep understanding of trends and thoroughly enjoy actively participating
                    in the creative aspects of content creation. I am firm in my belief that social media stands as an invaluable tool for bridging brands
                    with their intended audiences, fostering meaningful connections and nurturing these relationships over time.
                    Please take a moment to explore some of my showcased accomplishments within the realm of social media!</p>
                </div>
                <div className='col-xl-6 image-text'></div>
            </div>
            <div className='row image-container justify-content-center fade' id="nmc-social-right">
                {/* <div className="col-xl-1 col-lg-12"></div> */}
                <div className="col-xl-12 col-lg-12 p-4 image-text times text-responsive row">
                    <div className='col-xl-12 col-lg-12 image-text'>
                        <div id="nmc-social-left"></div>
                    </div>
                    <div className="col-xl-8 col-lg-12">
                        <p className='spaced'>As a Digital Media Coordinator at the National Music Council, I specialize in creating engaging digital content and collaborate across departments to craft impactful print and digital ads. I play a vital role in the success of the American Eagle Awards program, known for my attention to detail and organizational skills.

In managing social media, I oversee content creation, audience engagement, and platform performance analysis. I've achieved significant growth, including a 136% increase in Instagram followers and a remarkable 488% surge in Facebook engagement. I've also expanded my skills to include web design, incorporating HTML and CSS to create a user-friendly briefing paper page with a table of contents and embedded videos.</p>
                    </div>
                    <div className="white-text nmc-sm col-xl-4 justify-content-center">
                    <div><a target="_blank" href="https://www.instagram.com/nmcouncil/" ><img className='p-2' height="100px" width="100px" src="img/icons/sm/1.png" alt="..." /></a>@nmcouncil</div>
                    <div><a target="_blank" href="https://www.facebook.com/nationalmusiccouncil/" ><img className='p-2' height="100px" width="100px" src="img/icons/sm/2.png" alt="..." /></a>National Music Council</div>
                    <div><a target="_blank" href="https://linkedin.com/company/national-music-council/" ><img className='p-2' height="100px" width="100px" src="img/icons/sm/3.png" alt="..." /></a>National Music Council</div>
                    </div>
                </div>
            </div>
                
            <div id="nmc-imgs" className='row justify-content-center fade' style={{backgroundColor: '#E4DEE6'}}>
                <div id="carouselExampleSlidesOnly1" className="carouselExampleSlidesOnly1 slide carousel" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li data-target="#carouselExampleSlidesOnly1" data-slide-to="0" class="active"></li>
                        <li data-target="#carouselExampleSlidesOnly1" data-slide-to="1"></li>
                        <li data-target="#carouselExampleSlidesOnly1" data-slide-to="2"></li>
                        <li data-target="#carouselExampleSlidesOnly1" data-slide-to="3"></li>
                    </ol>
                    <a href="https://www.instagram.com/nmcouncil/" target='_blank'> 
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className='row' style={{display: 'flex'}}>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/rows/1.png" alt="..." />
                                    </div>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/rows/2.png" alt="..." />
                                    </div>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/rows/3.png" alt="..." />
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row' style={{display: 'flex'}}>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/rows/4.png" alt="..." />
                                    </div>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/rows/5.png" alt="..." />
                                    </div>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/rows/6.png" alt="..." />
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row' style={{display: 'flex'}}>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/rows/7.png" alt="..." />
                                    </div>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/rows/8.png" alt="..." />
                                    </div>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/rows/9.png" alt="..." />
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row' style={{display: 'flex'}}>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/rows/10.png" alt="..." />
                                    </div>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/rows/11.png" alt="..." />
                                    </div>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/rows/12.png" alt="..." />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                    <a class="carousel-control-prev" href="#carouselExampleSlidesOnly1" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleSlidesOnly1" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
                </div>
            </div>


            <div className='row image-container justify-content-center fade' id="cfa-social-right">
                {/* <div className="col-xl-1 col-lg-12"></div> */}
                <div className="col-xl-12 col-lg-12 p-4 image-text times text-responsive row">
                    <div className='col-xl-12 col-lg-12 image-text'>
                        <div id="cfa-social-left"></div>
                    </div>
                    <div className="col-xl-8 col-lg-12">
                        <p className='spaced'>
                        As a Marketing Assistant at Chick-fil-A South Plainfield (WSW Enterprises Inc), I organized and executed in-house special events, leading to sold-out events through effective marketing and planning. I prioritized exceptional customer service, contributed to social media management, and supported the team during peak periods. Additionally, I cultivated strong community relationships to enhance catering and onsite delivery sales. Notably, I successfully revamped the company's social media presence, achieving a 71% increase in engagement and expanding reach to over 6,500 new accounts within the first 30 days of my employment.

                        </p>
                    </div>
                    <div className="white-text cfa-sm col-xl-4 justify-content-center">
                    <div><a target="_blank" href="http://instagram.com/chickfilasouthplainfield/" ><img className='p-2' height="100px" width="100px" src="img/icons/sm/7.png" alt="..." /></a>@chickfilasouthplainfield</div>
                    <div><a target="_blank" href="https://www.facebook.com/cfasouthplainfield/" ><img className='p-2' height="100px" width="100px" src="img/icons/sm/8.png" alt="..." /></a>Chick-fil-A South Plainfield</div>
                    </div>
                </div>
            </div>

            <div id="cfa-imgs" className='row justify-content-center fade' style={{backgroundColor: '#E4DEE6'}}>
                <div id="carouselExampleSlidesOnly3" className="carouselExampleSlidesOnly3 slide carousel" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li data-target="#carouselExampleSlidesOnly3" data-slide-to="0" class="active"></li>
                        <li data-target="#carouselExampleSlidesOnly3" data-slide-to="1"></li>
                        <li data-target="#carouselExampleSlidesOnly3" data-slide-to="2"></li>
                        <li data-target="#carouselExampleSlidesOnly3" data-slide-to="3"></li>
                    </ol>
                    <a href="https://www.instagram.com/nmcouncil/" target='_blank'> 
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className='row' style={{display: 'flex'}}>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/cfa-posts/dogs/4.png" alt="..." />
                                    </div>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/cfa-posts/dogs/5.png" alt="..." />
                                    </div>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/cfa-posts/dogs/6.png" alt="..." />
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row' style={{display: 'flex'}}>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/cfa-posts/giveaway/1.png" alt="..." />
                                    </div>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/cfa-posts/giveaway/2.png" alt="..." />
                                    </div>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/cfa-posts/giveaway/3.png" alt="..." />
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row' style={{display: 'flex'}}>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/cfa-posts/giveaway/4.png" alt="..." />
                                    </div>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/cfa-posts/halloween/1.png" alt="..." />
                                    </div>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/cfa-posts/halloween/2.png" alt="..." />
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row' style={{display: 'flex'}}>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/cfa-posts/science-night/1.png" alt="..." />
                                    </div>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/cfa-posts/sm-posts/1.png" alt="..." />
                                    </div>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/cfa-posts/science-night/3.png" alt="..." />
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row' style={{display: 'flex'}}>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/cfa-posts/science-night/4.png" alt="..." />
                                    </div>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/cfa-posts/science-night/5.png" alt="..." />
                                    </div>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/cfa-posts/science-night/6.png" alt="..." />
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row' style={{display: 'flex'}}>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/cfa-posts/science-night/7.png" alt="..." />
                                    </div>
                                    
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/cfa-posts/science-night/2.png" alt="..." />
                                    </div>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/cfa-posts/sm-posts/2.png" alt="..." />
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row' style={{display: 'flex'}}>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/cfa-posts/sm-posts/3.png" alt="..." />
                                    </div>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/cfa-posts/sm-posts/8.png" alt="..." />
                                    </div>
                                    <div className='social-media-img col-sm-4'>
                                        <img src="img/social-media/cfa-posts/dogs/3.png" alt="..." />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                    <a class="carousel-control-prev" href="#carouselExampleSlidesOnly3" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleSlidesOnly3" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
                </div>
            </div>



            <div className='row image-container justify-content-center fade p-4 text-responsive' id="therapute-social">
                {/* <div className="col-xl-1 col-lg-12"></div> */}
                <div className='col-xl-12 col-lg-12 image-text'>
                    <div id="therapute-social-left"></div>
                </div>
                <div className="col-xl-8 col-lg-12 image-text times ">
                    <p className='spaced'>Therapute (now known as zant.) was a modern alternative to mental health
treatment offering 5+ categories of mental health services ranging from therapy,
life coaching, psychiatry, counseling, mentorship, and more. As a Public
Relations Intern, I held a crucial role in developing captivating owned media
content, contributing significantly to the creation of compelling blog posts and
tailored newsletters that resonated deeply with our target audience. Working
seamlessly alongside the marketing team, I assumed a central role in generating
high-caliber content across a diverse range of social media platforms, notably
including Instagram, LinkedIn, and TikTok. Noteworthy among my
accomplishments is the crafting of two impactful videos for Instagram reels, a
contribution that distinctly elevated engagement metrics, enriched brand
visibility, and notably directed substantial traffic to the company's website.</p>
                </div>
                <div className='white-text cfa-sm col-xl-4 justify-content-center'>
                    <div><a target="_blank" href="https://www.instagram.com/zant.app/" ><img className='p-2' height="100px" width="100px" src="img/icons/sm/4.png" alt="..." /></a>@zant.app</div>
                    <div><a target="_blank" href="https://www.facebook.com/zantapp/" ><img className='p-2' height="100px" width="100px" src="img/icons/sm/5.png" alt="..." /></a>zant</div>
                    <div><a target="_blank" href="https://www.linkedin.com/company/zant-app/" ><img className='p-2' height="100px" width="100px" src="img/icons/sm/6.png" alt="..." /></a>zant</div>
                </div>
            </div>
            <div id="thp-imgs" className='row justify-content-center fade' style={{backgroundColor: '#4D799A'}}>
                <div id="carouselExampleSlidesOnly2" className="w-100 carouselExampleSlidesOnly2 slide carousel" data-ride="carousel" style={{backgroundColor: 'E4DEE6'}}>
                    <ol class="carousel-indicators">
                        <li data-target="#carouselExampleSlidesOnly2" data-slide-to="0" class="active"></li>
                        <li data-target="#carouselExampleSlidesOnly2" data-slide-to="1"></li>
                        <li data-target="#carouselExampleSlidesOnly2" data-slide-to="2"></li>
                        <li data-target="#carouselExampleSlidesOnly2" data-slide-to="3"></li>
                    </ol>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <iframe muted controls poster preload autoPlay="false" title="therapute1" style={{height: "500px", minWidth: "20vw", overflow:  "hidden"}} frameBorder={0} className='muted col-lg-4 col-sm-12' src={'https://lesleeechivarre.s3.amazonaws.com/MeetOurTeam.mp4' } ></iframe>
                                    </div>
                                    <div className='col-sm-4'>
                                        <iframe muted controls poster preload autoPlay="false" title="therapute2" style={{height: "500px", minWidth: "20vw", overflow:  "hidden"}} frameBorder={0} className='muted col-lg-4 col-sm-12' src={'https://lesleeechivarre.s3.amazonaws.com/Therapute.mp4' } ></iframe>
                                    </div>
                                    <div className='col-sm-4'>
                                        <iframe muted controls poster preload autoPlay="false" title="therapute3" style={{height: "500px", minWidth: "20vw", overflow:  "hidden"}} frameBorder={0} className='muted col-lg-6 col-sm-12' src={'https://lesleeechivarre.s3.amazonaws.com/insta12.html' } ></iframe>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <iframe autoPlay="false"  muted controls poster preload title="therapute4" style={{height: "500px", minWidth: "20vw", overflow:  "hidden"}} frameBorder={0} className='muted col-lg-6 col-sm-12' src={'https://lesleeechivarre.s3.amazonaws.com/insta13.html' } ></iframe>
                                    </div>
                                    <div className='col-sm-4'>
                                        <video poster preload autoPlay="false" muted controls title="therapute5" style={{height: "500px", minWidth: "20vw", overflow:  "hidden"}} frameBorder={0} className='muted col-lg-4 col-sm-12' src={'https://lesleeechivarre.s3.amazonaws.com/As_It_Was_Harry.mov' } ></video>
                                    </div>
                                    <div className='col-sm-4'>
                                        <video poster preload autoPlay="false" muted controls title="therapute6" style={{height: "500px", minWidth: "20vw", overflow:  "hidden"}} frameBorder={0} className='muted col-lg-4 col-sm-12' src={'https://lesleeechivarre.s3.amazonaws.com/Coworkers_Busy.mov' } ></video>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <video poster preload autoPlay="false" muted controls title="therapute7" style={{height: "500px", minWidth: "20vw", overflow:  "hidden"}} frameBorder={0} className='muted col-lg-4 col-sm-12' src={'https://lesleeechivarre.s3.amazonaws.com/Coworkers_Busy1.mov' } ></video>
                                    </div>
                                    <div className='col-sm-4'>
                                        <video poster preload autoPlay="false" muted controls title="therapute8" style={{height: "500px", minWidth: "20vw", overflow:  "hidden"}} frameBorder={0} className='muted col-lg-4 col-sm-12' src={'https://lesleeechivarre.s3.amazonaws.com/Social_Media_Manager.mov' } ></video>
                                    </div>
                                    <div className='col-sm-4'>
                                        <video poster preload autoPlay="false" muted controls title="therapute9" style={{height: "500px", minWidth: "20vw", overflow:  "hidden"}} frameBorder={0} className='muted col-lg-4 col-sm-12' src={'https://lesleeechivarre.s3.amazonaws.com/Startup_Budget.mov' } ></video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <a class="carousel-control-prev" href="#carouselExampleSlidesOnly2" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleSlidesOnly2" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
                </div>
            </div>
            {/* <div className='row justify-content-center' style={{backgroundColor: '#E4DEE6', height: '500px'}}>
                <div className="carouselExampleSlidesOnly2 carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src="img/leslee.jpg" className="d-block w-100" alt="..." />
                        </div>
                        <div className="carousel-item">
                        <img src="img/leslee2.jpg" className="d-block w-100" alt="..." />
                        </div>
                        <div className="carousel-item">
                        <img src="img/leslee3.jpg" className="d-block w-100" alt="..." />
                        </div>
                    </div>
                </div>
            </div> */}

            <div id="after-grad" className='fade row image-container justify-content-center align-items-center' style={{backgroundColor: '#CFA9C9', color: 'black'}}>
                <div className="col-xl-4 col-lg-12"></div>
                <div className="col-xl-8 col-lg-12 p-4 image-text times text-responsive">
                    <p className='spaced'>After graduating, I ventured into freelance social
                    media and content creation. As a versatile social media manager, I craft engaging
                    posts and cater to diverse client needs, including content production and social media management.</p>
                </div>
            </div>
            <div id="leslee-imgs" className='row justify-content-center fade' style={{backgroundColor: '#4D799A'}}>
                <div id="carouselExampleSlidesOnly4" className="w-100 carouselExampleSlidesOnly4 slide carousel" data-ride="carousel" style={{backgroundColor: 'E4DEE6'}}>
                    <ol class="carousel-indicators">
                        <li data-target="#carouselExampleSlidesOnly4" data-slide-to="0" class="active"></li>
                        <li data-target="#carouselExampleSlidesOnly4" data-slide-to="1"></li>
                        <li data-target="#carouselExampleSlidesOnly4" data-slide-to="2"></li>
                        <li data-target="#carouselExampleSlidesOnly4" data-slide-to="3"></li>
                    </ol>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className='row'>
                                    <div className='leslee-img col-sm-4'>
                                        <img src="img/social-media/leslee/1.png" alt="..." />
                                    </div>
                                    <div className='leslee-img col-sm-4'>
                                        <img src="img/social-media/leslee/2.png" alt="..." />
                                    </div>
                                    <div className='leslee-img col-sm-4'>
                                        <img src="img/social-media/leslee/3.png" alt="..." />
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='leslee-img col-sm-4'>
                                        <img src="img/social-media/leslee/4.png" alt="..." />
                                    </div>
                                    <div className='leslee-img col-sm-4'>
                                        <img src="img/social-media/leslee/5.png" alt="..." />
                                    </div>
                                    <div className='leslee-img col-sm-4'>
                                        <img src="img/social-media/leslee/6.png" alt="..." />
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='leslee-img col-sm-4'>
                                        <img src="img/social-media/leslee/7.png" alt="..." />
                                    </div>
                                    <div className='leslee-img col-sm-4'>
                                        <img src="img/social-media/leslee/8.png" alt="..." />
                                    </div>
                                    <div className='leslee-img col-sm-4'>
                                        <img src="img/social-media/leslee/9.png" alt="..." />
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='leslee-img col-sm-4'>
                                        <img src="img/social-media/leslee/10.png" alt="..." />
                                    </div>
                                    <div className='leslee-img col-sm-4'>
                                        <img src="img/social-media/leslee/11.png" alt="..." />
                                    </div>
                                    <div className='leslee-img col-sm-4'>
                                        <img src="img/social-media/leslee/12.png" alt="..." />
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='leslee-img col-sm-4'>
                                        <img src="img/social-media/leslee/13.png" alt="..." />
                                    </div>
                                    <div className='leslee-img col-sm-4'>
                                        <img src="img/social-media/leslee/14.png" alt="..." />
                                    </div>
                                    <div className='leslee-img col-sm-4'>
                                        <img src="img/social-media/leslee/15.png" alt="..." />
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='leslee-img col-sm-4'>
                                        <img src="img/social-media/leslee/16.png" alt="..." />
                                    </div>
                                    <div className='leslee-img col-sm-4'>
                                        <img src="img/social-media/leslee/17.png" alt="..." />
                                    </div>
                                    <div className='leslee-img col-sm-4'>
                                        <img src="img/social-media/leslee/18.png" alt="..." />
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='leslee-img col-sm-4'>
                                        <img src="img/social-media/leslee/19.png" alt="..." />
                                    </div>
                                    <div className='leslee-img col-sm-4'>
                                        <img src="img/social-media/leslee/20.png" alt="..." />
                                    </div>
                                    <div className='leslee-img col-sm-4'>
                                        <img src="img/social-media/leslee/21.png" alt="..." />
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='leslee-img col-sm-4'>
                                        <img src="img/social-media/leslee/22.png" alt="..." />
                                    </div>
                                    <div className='leslee-img col-sm-4'>
                                        <img src="img/social-media/leslee/23.png" alt="..." />
                                    </div>
                                </div>
                            </div>
                        </div>
                    <a class="carousel-control-prev" href="#carouselExampleSlidesOnly4" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleSlidesOnly4" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
                </div>
            </div>

            {/* <div id='works' className='row col-12 justify-content-center' style={{margin:0}}>
                <h2 className="fade cormorant">national music council</h2>
                <div className="nmc insta-row mt-5 fade row justify-content-center">
                    <iframe className="muted" title="nmcouncil11" style={{height: "730px", overflow: "hidden"}} frameBorder={0} className='col-lg-4 col-sm-12' src={'https://lesleeechivarre.s3.amazonaws.com/insta19.html' } ></iframe>
                    <iframe className="muted" title="nmcouncil10" style={{height: "730px", overflow: "hidden"}} frameBorder={0} className='col-lg-4 col-sm-12' src={'https://lesleeechivarre.s3.amazonaws.com/insta18.html' } ></iframe>
                    <iframe className="muted" title="nmcouncil9" style={{height: "730px", overflow: "hidden"}} frameBorder={0} className='col-lg-4 col-sm-12' src={'https://lesleeechivarre.s3.amazonaws.com/insta17.html' } ></iframe>
                    <iframe className="muted" title="nmcouncil8" style={{height: "730px", overflow: "hidden"}} frameBorder={0} className='col-lg-4 col-sm-12' src={'https://lesleeechivarre.s3.amazonaws.com/insta16.html' } ></iframe>
                    <iframe className="muted" title="nmcouncil7" style={{height: "730px", overflow: "hidden"}} frameBorder={0} className='col-lg-4 col-sm-12' src={'https://lesleeechivarre.s3.amazonaws.com/insta15.html' } ></iframe>
                    <iframe className="muted" title="nmcouncil6" style={{height: "730px", overflow: "hidden"}} frameBorder={0} className='col-lg-4 col-sm-12' src={'https://lesleeechivarre.s3.amazonaws.com/insta14.html' } ></iframe>
                    <iframe className="muted" title="nmcouncil4" style={{height: "730px", overflow: "hidden"}} frameBorder={0} className='col-lg-4 col-sm-12' src={'https://lesleeechivarre.s3.amazonaws.com/insta8.html' } ></iframe>
                    <iframe className="muted" title="nmcouncil5" style={{height: "730px", overflow: "hidden"}} frameBorder={0} className='col-lg-4 col-sm-12' src={'https://lesleeechivarre.s3.amazonaws.com/insta9.html' } ></iframe>
                    <iframe className="muted" title="nmcouncil3" style={{height: "730px", overflow: "hidden"}} frameBorder={0} className='col-lg-4 col-sm-12' src={'https://lesleeechivarre.s3.amazonaws.com/insta7.html' } ></iframe>
                    <iframe className="muted" title="nmcouncil2" style={{height: "730px", overflow: "hidden"}} frameBorder={0} className='col-lg-4 col-sm-12' src={'https://lesleeechivarre.s3.amazonaws.com/insta6.html' } ></iframe>
                    <iframe className="muted" title="nmcouncil" style={{height: "730px", overflow: "hidden"}} frameBorder={0} className='col-lg-4 col-sm-12' src={'https://lesleeechivarre.s3.amazonaws.com/insta5.html' } ></iframe>
                    <iframe className="muted" title="zant2" style={{height: "730px", overflow: "hidden"}} frameBorder={0} className='col-lg-6 col-sm-12' src={'https://lesleeechivarre.s3.amazonaws.com/insta4.html' } ></iframe>
                    <iframe className="muted" title="zant" style={{height: "730px", overflow: "hidden"}} frameBorder={0} className='col-lg-6 col-sm-12' src={'https://lesleeechivarre.s3.amazonaws.com/insta3.html' } ></iframe>
                </div>
            </div> /** */}
            
            {/* <div className='works row col-12 justify-content-center' style={{margin:0}}>
                <h2 className="fade cormorant mt-5 mb-5">neiro.ai</h2>
                <div className="nmc insta-row fade row justify-content-center">
                </div>
            </div> */}
      </>
    )
}