import './videos.css'
export default function Videos() {
    return (
        <>
        <div id="videos" className="container">
            <h1 className="title cormorant fade mt-5">videos</h1>
            {/* <div className='row'> */}
                <div className='works row col-12 justify-content-center'>
                    <h2 className="cormorant fade">national music council</h2>
                    <div className="nmc insta-row mt-5 fade row justify-content-center" style={{"height": "100%"}}>
                        <iframe style={{padding: "10px"}} className="col-lg-6 col-sm-12" width="560" height="400" src="https://www.youtube.com/embed/QtYFu_YgcI0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        <iframe style={{padding: "10px"}} className="col-lg-6 col-sm-12" width="560" height="400" src="https://www.youtube.com/embed/w189VZ6AxQc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        <iframe style={{padding: "10px"}} className="col-lg-6 col-sm-12" width="560" height="400" src="https://www.youtube.com/embed/eZc7_Tt9Nls" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        <iframe style={{padding: "10px"}} className="col-lg-6 col-sm-12" width="560" height="400" src="https://www.youtube.com/embed/FuYy0tV1DYQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>
                <h2 className="cormorant fade mt-5 mb-5" style={{textAlign: "center"}}>stateliner tv</h2>
                <div className='works row col-12 justify-content-center'>
                    <div className="fade col-lg-6 col-md-12 col-xs-12" style={{width: "auto"}}>
                        <div className="text-align-center text-responsive">
                            <iframe width="auto" height="400" src="https://www.youtube.com/embed/Ng7uYacEB-s" title="YouTube video player" frameborder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                    <div className="fade col-lg-6 col-md-12 col-xs-12">
                        <div className="text-align-center text-responsive" style={{width: "auto"}}>
                            <video id="aag" width="100%" height="400" controls>
                                <source src="https://lesleeechivarre.s3.amazonaws.com/AAG.mp4" alt="Fourth slide" />
                            </video>
                        </div>
                    </div>
                </div>
                <h2 className="cormorant fade mt-5 mb-5" style={{textAlign: "center"}}>montclair state film projects</h2>
                <div className='works row col-12 justify-content-center'>
                <div className="fade col-lg-6 col-md-12 col-xs-12" style={{width: "auto"}}>
                        <div className="text-align-center text-responsive">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/BEUjjUe_umY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="fade col-lg-6 col-md-12 col-xs-12" style={{width: "auto"}}>
                        <div className="text-align-center text-responsive">
                            <iframe width="auto" height="400" src="https://www.youtube.com/embed/iRzxDe65x_8" title="YouTube video player" frameborder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                    <div className="fade col-lg-6 col-md-12 col-xs-12" style={{width: "auto"}}>
                        <div className="text-align-center text-responsive">
                            <iframe width="auto" height="400" src="https://www.youtube.com/embed/-9ymiTthKGU" title="YouTube video player" frameborder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                    <div className="fade col-lg-6 col-md-12 col-xs-12" style={{width: "auto"}}>
                        <div className="text-align-center text-responsive">
                            <iframe width="auto" height="400" src="https://www.youtube.com/embed/fzudE-xRrhU" title="YouTube video player" frameborder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            {/* </div> */}
      </div>
      </>
    )
}